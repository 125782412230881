<template>
  <div class="background">
    <v-container class="spacing-playground pa-lg-10" fluid>
      <v-card class="d-flex flex-column"
              :loading="loading">
        <v-card-title>
          <div class="logo">
            <app-logo/>
          </div>
          Cadastre-se
        </v-card-title>
        <v-divider/>
        <div :style="`overflow: auto; max-height: calc(100vh - ${190 + ($vuetify.breakpoint.lgAndUp ? 54 : 0)}px)`">
          <v-card-subtitle>
            Seja bem vindo ao sistema de Gestão da Winner Tennis. Informe corretamente seus dados, para que você possa
            acompanhar suas faturas, frequencias, incrições em campoentaos e etc.<br>Ao final do cadastro, enviaremos um
            e-mail com as intruções de acesso.
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="py-4 flex-grow-1">
            <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
              <cadastre-se-form :value="item"/>
            </v-form>
          </v-card-text>
        </div>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined color="primary" @click="() => $router.push({name: 'LoginCliente'})">Cancelar</v-btn>
          <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AppLogo from "../../components/app/AppLogo";
import CadastreSeForm from "../../components/shared/forms/CadastreSeForm";
import {clienteNovoCadastro} from "../../api/clientes";
import {ApiError} from "../../api/core";

export default {
  components: {AppLogo, CadastreSeForm},
  name: "CadastreSe",
  data() {
    return {
      item: {},
      formValid: false,
      loading: false,
    }
  },
  computed: {},
  methods: {
    async save() {
      if(!this.$refs.form.validate()) return;
      try {
        this.loading = true;
        await clienteNovoCadastro(this.item)
        this.$emit('input', false);
        this.$emit('onSave');
        this.$toast.success(`Cadastro efetuado com sucesso. Enviamos um e-mail com as instruçõe de acesso. Você será direcionado para a tela de login.`, {
          timeout: 3000
        })
        this.$router.push({name: 'LoginCliente'})
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível realizar o cadastro. Entre em contato com a Winner!'}.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

.background {
  background-image: url('../../assets/backgrounds/bg01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  flex: 1;

  .logo {
    padding: 5px;

    img {
      height: 60px;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
}

</style>